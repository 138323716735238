/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthCookiesService } from './auth-cookies.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private cookiesService: AuthCookiesService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = this.cookiesService.getIdTokenFromCookie();

        if (idToken) {
            const clonedRequest = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${idToken}`,
                },
            });

            return next.handle(clonedRequest);
        }

        return next.handle(req); // No token, continue with the request without Authorization header
    }
}

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { MsalService, MsalGuard, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { routes } from './app/app.routes';
import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { AuthInterceptor } from './app/services/auth-interceptor';
import { environment } from './environments/environment';

export function MSALInstanceFactory(): PublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.azureAdB2c.clientId,
            authority: `https://${environment.azureAdB2c.tenantName}.b2clogin.com/${environment.azureAdB2c.tenantName}.onmicrosoft.com/${environment.azureAdB2c.signIn}`,
            redirectUri: environment.azureAdB2c.redirectUri,
            knownAuthorities: [`${environment.azureAdB2c.tenantName}.b2clogin.com`],
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
        },
    });
}

export function MSALGuardConfigFactory() {
    return {
        interactionType: InteractionType.Popup,
        authRequest: {
            scopes: ['openid', 'profile', 'offline_access', 'User.Read'],
        },
    };
}

// Create an async initializer to call `initialize`
export function initializeMsal(msalInstance: PublicClientApplication) {
    return () => msalInstance.initialize();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(routes),
        provideHttpClient(withInterceptorsFromDi()),
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeMsal,
            deps: [MSAL_INSTANCE],
            multi: true,
        } as FactoryProvider, // Ensures MSAL initializes before app bootstrap
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
});

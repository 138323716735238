<div class="flex flex-col h-screen overflow-hidden">
    <!-- Header -->
    <header
        style="position: fixed; z-index: 20"
        class="bg-primary text-white w-full px-6 py-3 flex items-center shadow-md gap-2"
        [class.justify-between]="!authService.isUserLoggedIn"
    >
        <!-- Brand / Logo -->
        <div class="flex items-center flex-grow-0 mr-8 cursor-pointer">
            <img
                src="../assets/images/returnmail_logo_white.png"
                alt="Returnmail Logo"
                class="h-8 md:h-10"
                [routerLink]="authService.isUserLoggedIn ? '/upload' : '/login'"
            />
        </div>

        <!-- Hamburger Menu (mobile only) -->
        <button
            *ngIf="authService.isUserLoggedIn"
            class="md:hidden text-white font-semibold hover:bg-secondary-hover transition duration-300 p-2 rounded-md ml-auto"
            (click)="toggleSidenav()"
        >
            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
        </button>

        <!-- Navigation for Large Devices -->
        <nav *ngIf="authService.isUserLoggedIn" class="hidden md:flex space-x-4 flex-grow mx-auto">
            <ng-container *ngFor="let route of routes">
                <button
                    class="text-white font-medium hover:bg-tertiary-hover hover:shadow-md focus:outline-none transition duration-300 py-3 px-4 rounded-lg text-sm"
                    [ngClass]="isActiveRoute(route.path) ? 'bg-tertiary shadow-md font-bold' : 'hover:text-white'"
                    [routerLink]="route.path"
                >
                    {{ route.label }}
                </button>
            </ng-container>
        </nav>

        <div
            [class.hidden]="!authService.isUserLoggedIn"
            id="avatarButton"
            data-dropdown-toggle="userDropdown"
            data-dropdown-placement="bottom-start"
            class="shadow-md min-w-8 w-8 h-8 rounded-full flex items-center justify-center text-primary text-2xl font-semibold cursor-pointer overflow-hidden bg-lightGray"
        >
            <span *ngIf="!organizationData?.logo" class="block uppercase text-xs">
                {{ organizationData?.name || userName | initials }}
            </span>
            <img *ngIf="organizationData?.logo" [src]="organizationData?.logo" alt="Logo" class="w-16 h-16 object-contain" />
        </div>

        <!-- Dropdown menu -->
        <div
            id="userDropdown"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700 dark:divide-gray-600"
        >
            <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
                <div class="font-semibold flex flex-row gap-2 items-center">
                    <svg
                        class="w-5 h-5 text-gray-800 dark:text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"
                        />
                    </svg>
                    {{ organizationData?.name || userName }}
                </div>
            </div>
            <!-- <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
                <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                </li>
                <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                </li>
                <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                </li>
            </ul> -->
            <div class="cursor-pointer">
                <a
                    (click)="closeDropdown()"
                    routerLink="/settings"
                    class="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white flex flex-row gap-2 items-center"
                >
                    <svg
                        class="w-5 h-5 text-gray-800 dark:text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
                        />
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        />
                    </svg>
                    Settings
                </a>
            </div>

            <div class="cursor-pointer">
                <a
                    class="text-center text-secondary font-bold block px-4 py-3 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    (click)="logOut()"
                >
                    Sign Out
                </a>
            </div>
        </div>

        <!-- <button
            *ngIf="authService.isUserLoggedIn"
            class="bg-secondary text-white font-semibold py-2 px-6 rounded-full hover:bg-secondary-hover transition duration-300 hidden md:flex text-sm shadow-md"
            (click)="logOut()"
        >
            Logout
        </button> -->
    </header>

    <!-- Sidenav Overlay for Small Devices -->
    <div
        class="fixed inset-0 bg-overlay bg-opacity-50 z-30 md:hidden"
        *ngIf="isSidenavOpen"
        (click)="toggleSidenav()"
        (keydown.enter)="toggleSidenav()"
        (keydown.space)="toggleSidenav()"
        role="button"
        tabindex="0"
        aria-label="Close navigation menu"
    ></div>

    <!-- Sidebar for Small Devices -->
    <div
        class="fixed left-0 top-0 h-full bg-primary text-white w-64 transition-transform transform md:hidden z-40"
        [ngClass]="{
            'translate-x-0': isSidenavOpen,
            '-translate-x-full': !isSidenavOpen,
        }"
    >
        <nav class="p-4">
            <ng-container *ngFor="let route of routes">
                <button
                    class="flex items-center block text-white font-medium hover:bg-tertiary-hover hover:shadow-md focus:outline-none transition duration-300 w-full h-12 px-4 rounded-lg mb-4 text-sm"
                    [ngClass]="isActiveRoute(route.path) ? 'bg-tertiary shadow-md font-bold' : 'hover:text-white'"
                    [routerLink]="route.path"
                    (click)="isSidenavOpen = false"
                >
                    {{ route.label }}
                </button>
            </ng-container>

            <!-- Logout (for mobile view) -->
            <button
                class="block text-white font-semibold bg-secondary hover:shadow-md hover:bg-secondary-hover focus:outline-none transition duration-300 w-1/2 py-2 px-6 rounded-full mb-4 shadow-md mx-auto"
                *ngIf="authService.isUserLoggedIn"
                (click)="logOut()"
            >
                Sign out
            </button>
        </nav>
    </div>

    <!-- Main Content -->
    <div class="flex-1 w-full flex justify-center p-2 md:p-4 mx-auto mt-14 overflow-auto">
        <div class="px-2 w-full max-w-full pb-2 pt-2">
            <router-outlet></router-outlet>
            <ng-container setAsDynamicContainer></ng-container>
        </div>
    </div>
</div>

<app-loader *ngIf="loading"></app-loader>

import { ChangeDetectorRef, Directive, ViewContainerRef } from '@angular/core';
import { DynamicContentService } from './dynamic-content.service';

@Directive({
    standalone: true,
    selector: '[setAsDynamicContainer]', // eslint-disable-line
})
export class SetAsDynamicContainerDirective {
    constructor(
        private dynamicContentService: DynamicContentService,
        private viewContainerRef: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.dynamicContentService.setViewRefAndChangeDetectorRef(viewContainerRef, changeDetectorRef);
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MsalService } from '@azure/msal-angular';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthRedirectGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private msalService: MsalService
    ) {}

    canActivate(): Observable<boolean> {
        if (this.authService.isUserLoggedIn) {
            return of(true);
        }

        return from(this.msalService.instance.handleRedirectPromise()).pipe(
            switchMap((response) => {
                if (response?.account) {
                    this.msalService.instance.setActiveAccount(response.account);
                    this.authService.isUserLoggedIn = true;
                    return of(true);
                } else {
                    this.router.navigate(['login']);
                    return of(false);
                }
            }),
            catchError(() => {
                this.router.navigate(['login']);
                return of(false);
            })
        );
    }
}

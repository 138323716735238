import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth.service';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { SetAsDynamicContainerDirective } from './components/popups/set-as-dynamic-container.directive';
import { DbService } from './services/db-service';
import { OrganizationInfo } from '../models/organization-info.interface';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { InitialsPipe } from './pipes/name-initials.pipe';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, RouterModule, LoaderComponent, SetAsDynamicContainerDirective, InitialsPipe],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    public isSidenavOpen = false;
    public loading = false;
    public organizationData!: OrganizationInfo | null;
    public userName!: any;
    public dropdownOpened = false;
    public isAdmin = false;

    public routes = [
        { path: '/upload', label: 'Upload' },
        { path: '/scanned', label: 'Scanned' },
        { path: '/reviewed', label: 'Reviewed' },
        { path: '/library', label: 'Library' },
    ];

    constructor(
        public authService: AuthService,
        private router: Router,
        private dbService: DbService,
        private msalService: MsalService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await this.authService.initializeAccount();
        this.loading = false;
        if (this.authService.isUserLoggedIn) {
            const activeAccount: AccountInfo | null = this.msalService.instance.getActiveAccount();
            if (activeAccount) {
                const roles = activeAccount.idTokenClaims?.['extension_Roles'] as string[] | undefined;
                const isAdmin = !!(roles && roles.includes('organizationAdmin'));
                this.isAdmin = isAdmin;
                if (isAdmin) {
                    this.getOrganizationDetails();
                } else {
                    if (activeAccount.idTokenClaims) {
                        this.userName = activeAccount.idTokenClaims['extension_FullName'];
                    }
                }
            }
        }
    }

    public async logOut(): Promise<void> {
        this.loading = true;
        await this.authService.logOut();
    }

    public async logIn(): Promise<void> {
        this.loading = true;
        await this.authService.logIn();
    }

    public toggleSidenav() {
        this.isSidenavOpen = !this.isSidenavOpen;
    }

    public isActiveRoute(route: string): boolean {
        return this.router.isActive(route, true); // 'true' ensures exact matching
    }

    public toggleDropdown(): void {
        this.dropdownOpened = !this.dropdownOpened;
    }

    public closeDropdown() {
        const dropdown = document.getElementById('userDropdown');
        if (dropdown) {
            dropdown.classList.add('hidden');
        }
    }

    private getOrganizationDetails(): void {
        this.dbService.getOrganizationDetails().subscribe({
            next: (res) => {
                if (res) {
                    this.dbService.organizationData$.next(res);
                    this.organizationData = res;
                }
            },
            error: () => {
                this.loading = false;
            },
            complete: () => {
                this.loading = false;
            },
        });
    }
}
